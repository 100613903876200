<script>
import BaseButton from '/~/components/base/button/base-button.vue'

export default {
  name: 'nab-verification-form',
  components: {
    BaseButton,
  },
  props: {
    startUrl: {
      type: String,
      default: null,
    },
  },
  mounted() {
    window.addEventListener('message', this.messageHandler)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.messageHandler)
  },
  methods: {
    messageHandler(event) {
      if (event instanceof MessageEvent) {
        const { data } = event

        if (typeof data !== 'object') {
          return
        }

        switch (data.action) {
          case '3DSECURE_COMPLETE':
            this.$emit('done', data.data)
            break
          case '3DSECURE_FAILED':
            this.$emit('fail', data.data)
            break
        }
      }
    },
  },
}
</script>

<template>
  <div class="flex grow flex-col items-center justify-between">
    <iframe
      ref="iframe"
      name="3DSecure"
      :src="startUrl"
      class="block min-h-[400px] w-full"
      scrolling="no"
    />

    <div class="flex w-full grow flex-col justify-end px-[30px] pb-5">
      <base-button
        color="error"
        full-width
        class="mt-6 text-lg"
        look="outlined"
        @click="$emit('cancel')"
      >
        Cancel
      </base-button>
    </div>
  </div>
</template>
